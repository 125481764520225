import React from 'react'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import App from 'App'
import Image from 'components/LocalImage'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import SEO from 'components/SEO'
import Button from 'components/Button'
import illustration from 'static/svg/404.svg'
import bgImage from 'static/images/bg_page_header.svg'

const BgImg = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  z-index: 0;
`

const StyledButtonWithLoading = styled(Button.Gradient)`
  height: 50px;
  width: 234px;
`

const StyledErrorMessageText = styled(Text)`
  line-height: 32px;
`

const Error = () => (
  <App isHeaderVisible={false}>
    <SEO title="Not found" description="Access is denied." />
    <Flex position="relative" width="100%" height="100%" bg={COLOR_CONSTANTS.WHITE}>
      <BgImg src={bgImage} />
      <Flex mx="auto" flexDirection="column" alignItems="center">
        <Image
          mt="m"
          src={illustration}
          maxHeight="400px"
          maxWidth={{ mobile: '300px', mobileLarge: '100%', tablet: '100%' }}
        />
        <Text my="m" color={COLOR_CONSTANTS.SKY} fontSize="60px" fontWeight="bold" textAlign="center">
          Whoops !
        </Text>
        <StyledErrorMessageText
          mt="m"
          pt="s"
          fontSize="m"
          color={COLOR_CONSTANTS.DENIM}
          fontWeight="bold"
          textAlign="center"
        >
          Access is denied.
        </StyledErrorMessageText>
        <Flex my="l" justifyContent="center">
          <StyledButtonWithLoading
            isLarge
            onClick={() => {
              window.location.href = '/'
            }}
            mt="m"
            width="100%"
          >
            <Text fontWeight="bold">Back to home page</Text>
          </StyledButtonWithLoading>
        </Flex>
      </Flex>
    </Flex>
  </App>
)

export default Error
